import { ParsedUrlQuery } from 'querystring'
import React, { FC } from 'react'
import { GetServerSidePropsContext, PreviewData } from 'next'
import { AdPlacementType } from '@sport1/types/web'
import { getSport1Navigation } from './api/cms/navigation'
import { getLayoutBySlug } from './api/cms/content/tag/[id]'
import PageContentLayout from '@/layouts/Standard/PageContentLayout'
import StandardLayout from '@/layouts/Standard'
import { mockMetaData } from '@/testUtils/mockData/metaData'
import { PageProps } from '@/types/page'
import useLayoutPaging from '@/hooks/useLayoutPaging'
import extendHome from '@/utils/seo/extendHome'
import { addAdsToLayout, AdPlatform } from '@/helpers/adHelper'

const Home: FC<PageProps> = ({ layoutData, navigation }) => {
    const { pagedLayout } = useLayoutPaging('/', layoutData)

    return (
        <StandardLayout navigation={navigation}>
            <PageContentLayout layoutData={pagedLayout} />
        </StandardLayout>
    )
}

export const getServerSideProps = async (
    ctx: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>
) => {
    const [navigationData, layout] = await Promise.all([
        getSport1Navigation(),
        getLayoutBySlug('/'),
    ])

    addAdsToLayout({
        adInfos: [
            {
                placement: AdPlacementType.INPAGE,
                platform: AdPlatform.WEB,
            },
        ],
        layout,
    })

    ctx.res.setHeader('deeplink', `sport1://home`)
    // function has only use as long metadata are set from mockfiles

    const layoutData = {
        ...layout,
        meta: {
            ...layout.meta,
            // TODO: remove when we get the actual meta data from backend + add type to sport1-types
            data: mockMetaData,
        },
    }
    extendHome({ layoutProps: layoutData })

    // Special logic for exatlon
    const now = new Date()
    const year = now.getUTCFullYear()
    const month = now.getUTCMonth()
    const day = now.getUTCDate()
    const hour = now.getUTCHours()
    // !!!!!!UTC Time
    if (!(year === 2024 && month === 8 && day === 16 && hour > 3 && hour < 19)) {
        layoutData.components = layoutData.components.filter(
            comp => !(comp.type === 'EVENT_TEASER_CARD' && comp?.competition.name === 'Exatlon')
        )
    }

    //TODO: check for 404
    return {
        props: {
            navigation: navigationData,
            layoutData,
        },
    }
}

export default Home
